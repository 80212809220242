import React, { useRef, useState } from "react";
import CustomerChoosesUs from "common/CustomerChoosesUs";
import FooterMain from "./partials/footer/FooterMain";
import WorksOffer from "common/WorksOffer";
import { useSuperServices } from "hooks/useService";
import { useNavigate } from "react-router-dom";
import { useHomepage_videos } from "hooks/useUser";
import Collaborators from "common/Collaborators";
import { showErrorToast } from "store/features/toastSlice";
import { useDispatch } from "react-redux";
import TopTrendingServices from "common/TopTrendingServices";
import HomeCarousel from "../views/partials/sliders/HomeCarousel";
import CustomerLoveUs from "../theme/cards/CustomerLoveUs";
import CorporateClientFeedback from "common/CorporateClientFeedback";


const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: homepage_videos, videos_isLoading } = useHomepage_videos();
  const {
    isLoading,
    isError,
    error,
    data: super_services,
  } = useSuperServices();
  const selectCityRef = useRef(null);
  const [cityFilterBorder, setCityFilterBorder] = useState(false);

  const handleClick = (item) => {
    if (localStorage.getItem("selectedOption") === null) {
      dispatch(showErrorToast("Select the City First"));
      setCityFilterBorder(true);
      selectCityRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start"
      })
    } else if (item?.super_category_web_seo_url !== "") {
      navigate(item?.super_category_web_seo_url.match(/\/([^/]+)$/)[0], {
        state: {
          web_banner_image: item?.web_banner_image,
          web_banner_text: item?.web_banner_text,
          super_category_id: item?.super_category_id,
          super_category_name: item?.super_category_name,
          category_type: "category",
          super_category_description: item?.super_category_description,
        },
      });
    } else {
      navigate(`/services/${item?.super_category_slug}`, {
        state: {
          web_banner_image: item?.web_banner_image,
          web_banner_text: item?.web_banner_text,
          category_id: item?.super_category_id,
          super_category_name: item?.super_category_name,
          category_type: "category",
          super_category_description: item?.super_category_description,
        },
      });
    }
  };

  if (isError) return `Error - ${error}`;

  return (
    <>
      <div className="w-full">
        {/* -------------------- Home Carousel ----------------------- */}
        <div className="h-full">

          <HomeCarousel />
        </div>

        {/* -------------------- Home Section ----------------------- */}

        <div className=" w-full text-center">
          <div className="w-[90%] mx-auto">
            {/* -------------------- Top Trending Services Section ----------------------- */}

            <div ref={selectCityRef}>
              <TopTrendingServices cityFilterBorder={cityFilterBorder} setCityFilterBorder={setCityFilterBorder} />
            </div>

            <div className="py-[60px]">
              <span className="font-bold text-3xl md:text-[36px] leading-[43.2px]">
                Karsaaz Services
              </span>
            </div>

            <div className="grid grid-cols-2  md:grid-cols-3 lg:grid-cols-4 gap-y-3 sm:gap-y-16 md:gap-y-24 gap-x-3  mt-5 md:mt-[70px] mx-auto">
              {isLoading
                ? Array(4)
                  .fill()
                  .map((_, index) => (
                    <div
                      key={index}
                      className="w-full md:w-[90%] flex flex-col gap-4 md:gap-8 mx-auto justify-between bg-white drop-shadow-md rounded-xl p-3"
                    >
                      <div className="animate-pulse rounded-xl w-full h-[150px] md:h-[210.22px] bg-slate-200"></div>
                      <div className="p-1 md:p-3 px-0 md:px-6 space-y-2 md:space-y-4">
                        <div className="">
                          <div className="animate-pulse rounded-xl w-full h-[15px] md:h-[22px] bg-slate-200"></div>
                        </div>
                      </div>
                    </div>
                  ))
                : super_services?.data?.en?.map((item, index) => (
                  <button key={index} onClick={() => handleClick(item)}>
                    <div className="flex flex-col justify-between items-center h-full sm:h-[210px] md:h-[198px] border rounded-xl relative bg-opacity-[0.15]  bg-[#D6EFFF] p-3">
                      <div className="sm:m-3 m-0 sm:absolute top-[-33%] md:top-[-53%]">
                        <img
                          src={item?.super_category_icon}
                          alt={item?.super_category_name}
                          className="w-[191px] sm:w-[248.29px] md:w-[235.29px] mx-auto"
                        />
                      </div>

                      <div className="rounded-xl sm:absolute bottom-[2%] md:py-1 lg:py-2">
                        <h3 className="font-bold text-[14px] lg:text-[18px] leading-[16px] md:leading-[31px] text-center">
                          {item?.super_category_name}
                        </h3>
                      </div>
                    </div>
                  </button>
                ))}
            </div>
          </div>

          <CustomerChoosesUs color="#ECF7FE" TextColor="black" />

          {/* ------------------------------- How Karsaaz Works Section ------------------------------- */}

          <div className="w-[90%] mx-auto flex flex-col justify-between items-center my-20 p-2 md:p-5 border rounded-3xl">
            <div className="  w-full lg:w-[848px] ">
              <div className="font-Inter font-bold text-3xl md:text-[36px] leading-[43.2px] text-center capitalize">
                Our Unique Booking Options
              </div>
              <p className="font-normal text-base md:text-[18px] leading-[31px] mt-[23px] text-center ">
                In a few easy clicks, you can use the Karsaaz to Urgent Booking
                and Get offers option the Cheapest and most Reliable
                Professionals and get all of your work done anywhere in Lahore.
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 md:gap-[41.46px] mt-[73px] ">
              <div className="flex flex-col gap-4 items-center">
                <WorksOffer
                  heading="Urgent Booking"
                  first_line="Select Service"
                  second_line="Choose Urgent Booking Option"
                  third_line="Provider at your Location in 59 Minutes"
                />
                <h3 className="  text-center font-bold text-[14px] leading-[16.8px]">
                  {" "}
                  Watch Video
                </h3>

                <div className="w-full mx-auto">
                  {videos_isLoading ? (
                    <div
                      className="flex flex-col gap-3 justify-between w-full  bg-white drop-shadow-md rounded-2xl p-3">
                      <div className="animate-pulse rounded-xl  h-[325.22px] bg-sky-200"></div>
                    </div>
                  ) : (
                    <iframe
                      className="rounded-[10px]"
                      width="100%"
                      height="257px"
                      src={homepage_videos?.data?.[1]?.value}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    ></iframe>
                  )}
                </div>

              </div>
              <div className="hidden lg:block">
                <img
                  src={`${process.env.PUBLIC_URL}/images/IphoneFullWaxImg.svg`}
                  alt=""
                  className="object-cover"
                />
              </div>
              <div className="flex flex-col gap-4 items-center">
                <WorksOffer
                  heading="Multiple offers"
                  first_line="Select Service"
                  second_line="Get 3 Offers from Providers"
                  third_line="Choose Best Price!"
                />
                <h3 className="text-center font-bold text-[14px] leading-[16.8px]">
                  {" "}
                  Watch Video
                </h3>

                <div className="w-full mx-auto">
                  {videos_isLoading ? (
                    <div
                      className="flex flex-col gap-3 justify-between w-full  bg-white drop-shadow-md rounded-2xl p-3">
                      <div className="animate-pulse rounded-xl  h-[325.22px] bg-sky-200"></div>
                    </div>
                  ) : (
                    <iframe
                      className="rounded-[10px]"
                      width="100%"
                      height="257px"
                      src={homepage_videos?.data?.[2]?.value}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    ></iframe>
                  )}
                </div>

              </div>
            </div>
          </div>
        </div>

        {/* <KarsaazWorks /> */}
        {/* <TopTrendingServices color="#0973BA" /> */}

        <CustomerLoveUs
          color=""
          QuoteIconColor="#07609C"
          FaArrowCircleColor="#07609C"
          TextColor="black"
        />
        <Collaborators />
        {/* <GetMoreDoneWithKarsaaz color="#ECF7FE" TextColor="#0973BA" /> */}

        <CorporateClientFeedback />
        <FooterMain />
      </div>
    </>
  );
};

export default Home;